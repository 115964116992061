@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --animation-speed: 1000ms;
  --animation-speed-fast: 250ms;
  --default-cell-bg-color: theme('colors.white');
  --default-cell-border-color: theme('colors.black');
  --default-cell-text-color: theme('colors.black');
  --absent-cell-bg-color: theme('colors.slate.400');
  --correct-cell-bg-color: theme('colors.green.400');
  --present-cell-bg-color: theme('colors.yellow.400');
}

.dark {
  --default-cell-bg-color: theme('colors.slate.900');
  --default-cell-border-color: theme('colors.white');
  --default-cell-text-color: theme('colors.white');
  --absent-cell-bg-color: theme('colors.slate.700');
}

.high-contrast {
  --correct-cell-bg-color: theme('colors.orange.400');
  --present-cell-bg-color: theme('colors.cyan.400');
}

.cell-fill-animation {
  animation: onTypeCell linear;
  animation-duration: 0.35s;
}

.cell-reveal {
  animation-duration: 0.35s;
  animation-timing-function: linear;
  animation-fill-mode: backwards;
}

.cell-reveal.absent {
  animation-name: revealAbsentCharCell;
}

.cell-reveal.correct {
  animation-name: revealCorrectCharCell;
}

.cell-reveal.present {
  animation-name: revealPresentCharCell;
}

.cell-reveal > .letter-container {
  animation: offsetLetterFlip 0.35s linear;
  animation-fill-mode: backwards;
}

svg.cursor-pointer {
  transition: all var(--animation-speed-fast);
}

svg.cursor-pointer:hover {
  transform: scale(1.2);
}

.jiggle {
  animation: jiggle linear;
  animation-duration: var(--animation-speed-fast);
}

.navbar {
  margin-bottom: 2%;
}

.navbar-content {
  display: flex;
  height: 3rem;
  align-items: center;
  justify-content: space-between;
}

.right-icons {
  display: flex;
}

@keyframes revealAbsentCharCell {
  0% {
    transform: rotateX(0deg);
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
  }
  50% {
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
  }
  50.1% {
    background-color: var(--absent-cell-bg-color);
    border-color: var(--absent-cell-bg-color);
  }
  100% {
    transform: rotateX(180deg);
  }
}

@keyframes revealCorrectCharCell {
  0% {
    transform: rotateX(0deg);
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
  }
  50% {
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
  }
  50.1% {
    background-color: var(--correct-cell-bg-color);
    border-color: var(--correct-cell-bg-color);
  }
  100% {
    transform: rotateX(180deg);
  }
}

@keyframes revealPresentCharCell {
  0% {
    transform: rotateX(0deg);
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
  }
  50% {
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
  }
  50.1% {
    background-color: var(--present-cell-bg-color);
    border-color: var(--present-cell-bg-color);
  }
  100% {
    transform: rotateX(180deg);
  }
}

/* Additional animation on the child div to avoid letters turning upside down/snapping back to upright visual glitch */
@keyframes offsetLetterFlip {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(180deg);
  }
}

@keyframes onTypeCell {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.shadowed {
  text-shadow: 1px 1px 1px #000000;
}

@keyframes jiggle {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-0.5rem, 0);
  }
  50% {
    transform: translate(0.5rem, 0);
  }
  75% {
    transform: translate(-0.5rem, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

.show-picture-btn {
  margin-top: 10px;
  width: 70px;
  height: 50px;
}

.loading-svg-container {
  margin-top: 155px;
  margin-bottom: 155px;
}

.loading-svg {
  width: 50px;
  height: 50px;
}

.table-pic {
  width: 174px;
  height: 174px;
}

td {
  padding-right: 3px;
}

.keyboard {
  margin-bottom: 40px;
}

.fill {
  object-fit: fill;
}

.contain {
  object-fit: contain;
}

.cover {
  object-fit: cover;
}

.scale-down {
  object-fit: scale-down;
}

.unlimited-mode-button {
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 350px;
  font-size: xx-large;
}

.login {
  margin-top: 40px;
  margin-bottom: 40px;
}

.country-selector {
  max-width: 150px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: '2em';
  height: 30px;
  text-align: center;
  font-weight: 600;
}

.countries-leader-board {
  margin-top: 10px;
  margin-bottom: 10px;
}

.add-score {
  margin-top: 20px;
}

.pledge-score-btn {
  background-color: #4f46e5;
}

.pledge-score-btn:hover {
  background-color: #443dca;
}

.pledge-score-btn:disabled {
  background-color: #686868;
}

.pexels-logo {
  margin-top: 10px;
  width: 200px;
}

.qene-logo {
  margin-top: 30px;
  width: 50px;
}
